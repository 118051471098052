<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <h3 class="modal-card-title is-size-4">
        {{ $t("download-dialog-header") }}
      </h3>
    </header>
    <section class="modal-card-body">
      <div class="field grey-bottom">
        <b-checkbox :value="allSelected" @input="toggleSelectionAll">{{
          $t(`de/select-all`)
        }}</b-checkbox>
      </div>
      <div
        v-for="type in downloadTypes"
        :key="type.key"
        v-show="type.visible"
        class="field"
      >
        <b-checkbox
          v-show="type.visible"
          v-model="selectedDownloadTypes"
          :native-value="type.value"
          :disabled="type.disabled"
          >{{ $t(`download_types.${type.label}`) }}</b-checkbox
        >
      </div>
    </section>
    <footer class="modal-card-foot h-centered">
      <button class="button" type="button" @click="$parent.close()">
        <b-icon icon="close"></b-icon>
        <span>{{ $t("buttons.close") }}</span>
      </button>
      <button class="button is-primary" type="button" @click="download">
        <b-icon icon="download"></b-icon>
        <span>{{ $t("buttons.download") }}</span>
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  // name: "ModalTypeSelection",
  props: {
    downloadTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedDownloadTypes: [],
      allSelected: false,
    };
  },
  methods: {
    download() {
      this.$emit("download", this.selectedDownloadTypes);
    },
    selectAll() {
      const selected = [];
      this.downloadTypes.forEach((type) => {
        if (!type.disabled && type.visible) {
          selected.push(type.value);
        }
      });
      this.selectedDownloadTypes = selected;
      this.allSelected = true;
    },
    deselectAll() {
      this.selectedDownloadTypes = [];
      this.allSelected = false;
    },
    toggleSelectionAll() {
      if (this.allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
  },
  created() {
    this.selectAll();
  },
};
</script>

<style>
.modal-card-foot.h-centered {
  justify-content: center;
}
.grey-bottom {
  border-bottom: 1px solid gray;
}
</style>
