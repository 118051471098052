<template>
  <div class="table-class">
    <b-modal
      :active.sync="displayColumnFilterModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      @close="onCloseModalColFilter"
    >
      <template #default>
        <TableColumnFilter
          :columns="displayedColumnsFilterColumn"
          :data="columns"
          @order-change="onTableColumnsOrderChange"
          @toggleVisibility="onTableColumnsToggleVisibility"
          @toggleVisibilityAll="onTableColumnsToggleVisibilityAll"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
          <template #footer>
            <p v-if="showEmitStateCheckbox" class="is-flex-grow-1">
              <b-field>
                <b-checkbox v-model="emitColumnsStateClose">
                  {{ $t("table.col-options.apply-also-to-filters") }}
                </b-checkbox>
              </b-field>
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <b-modal
      :active.sync="displayDownloadDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <modal-type-selection
          :downloadTypes="downloadTypes"
          @download="downloadSelected"
        ></modal-type-selection>
      </template>
    </b-modal>

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <button
            type="button"
            class="button is-primary"
            @click="displayColumnFilterModal = true"
          >
            <b-icon icon="glasses"></b-icon>
            <span>{{
              isMobile
                ? $t("document.detail.mobile.buttons.filter_data")
                : $t("document.detail.buttons.filter_data")
            }}</span>
          </button>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item" v-if="checkable">
          <span v-show="!isSelectedTotal"
            >{{
              $t("table.selected_num_of_total", {
                selectedNum: selectedDocList.length,
                totalNum: Math.min(l_perPage, totalRecords),
              })
            }}
            <a v-show="totalRecords > l_perPage" @click="selectTotal">{{
              $t("table.select_all_elements", { totalRecords: totalRecords })
            }}</a></span
          >
          <a v-show="isSelectedTotal" @click="unselectTotal">{{
            $t("table.unselect_all_elements", { elemNum: totalRecords })
          }}</a>
        </div>
        <div
          class="level-item"
          v-if="downloadTypes && downloadTypes.length > 0"
        >
          <button
            class="button is-primary"
            :disabled="selectedDocList.length === 0"
            @click="openDownloadDialog"
          >
            {{ $t("buttons.download_selected") }}
          </button>
        </div>
      </div>
    </div>

    <!-- Data Table -->
    <div class="document-table-container">
      <b-table
        :data="data"
        :loading="isLoadingRows"
        v-bind="tableConfig"
        :row-class="(row, index) => (clickable ? 'is-clickable' : '')"
        paginated
        backend-pagination
        :total="totalRecords"
        :per-page="l_perPage"
        @page-change="onPageChange"
        :checkable="checkable"
        :checked-rows.sync="selectedDocList"
        @check="onCheckRows"
        :detailed="detailed"
        :sticky-header="true"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @click="onClick"
        @contextmenu="onContextMenu"
        :backend-sorting="backendSortingEnabled"
        :sort-multiple="true"
        :sort-multiple-key="null"
        :sort-multiple-data="sortingPriority"
        @sort="onSort"
        @sorting-priority-removed="onSortingPriorityRemoved"
        ref="table"
      >
        <b-table-column
          v-for="(column, index) of columns"
          :key="column.field + '_' + index"
          :label="column.label"
          :field="column.field"
          :visible="column.visible"
          :sortable="column.sortable"
          :centered="column.centered"
          :header-class="
            column.headerClass ? column.headerClass : 'custom-header'
          "
          :cell-class="column.cellClass ? column.cellClass : 'custom-cell'"
        >
          <!-- This is the worst thing ever to make because of all the $refs and even worst accessing child data with the ref.
              But is inevitable because fixes the styling bug that the component table has when sorting causing the arrow and delete button to disappear in the following column.
              TLDR; TODO: Remove this template once the component fixes his own style.
         -->
          <template v-slot:header="slotProps">
            {{ slotProps.column.label }}
            <template
              v-if="
                slotProps.column.sortable &&
                $refs.table.sortMultipleDataComputed.filter(
                  (i) => i.field === slotProps.column.field
                ).length > 0
              "
            >
              <span class="sorting-options">
                <b-icon
                  icon="arrow-up"
                  both
                  size="is-small"
                  :class="{
                    'is-desc':
                      $refs.table.sortMultipleDataComputed.filter(
                        (i) => i.field === slotProps.column.field
                      )[0].order === 'desc',
                  }"
                />
                {{ $refs.table.findIndexOfSortData(slotProps.column) }}
                <button
                  class="delete is-small"
                  type="button"
                  @click.stop="
                    $refs.table.removeSortingPriority(slotProps.column)
                  "
                />
              </span>
            </template>
          </template>

          <!-- <template v-slot="slotProps"> -->

          <template #default="slotProps">
            <slot
              name="cell"
              v-bind:cellData="slotProps.row[column.field]"
              v-bind:row="slotProps.row"
              v-bind:column="column"
              >{{ slotProps.row[column.field] }}</slot
            >
          </template>
          <!-- <span
            :class="computeCellClass(column.field, slotProps.row[column.field])"
            >{{
              documentType &&
              documentType === "einvoice" &&
              column.field === "status"
                ? $t(`einvoice.status.options.${slotProps.row[column.field]}`)
                : slotProps.row[column.field]
            }}</span
          > -->
          <!-- </template> -->
        </b-table-column>

        <template #detail="props">
          <slot name="detail" v-bind="props"></slot>
        </template>

        <template #footer>
          {{
            data.length > 0
              ? $t("current_page_report", {
                  first: l_perPage * (l_currentPage - 1) + 1,
                  last: Math.min(l_perPage * l_currentPage, totalRecords),
                  totalRecords: totalRecords,
                })
              : ""
          }}
        </template>

        <template slot="bottom-left">
          <!-- <b-select
            v-model="l_perPage"
            :disabled="!isPaginated"
            @input="onPerPageChange"
          >
            <option
              v-for="option in rowsPerPageOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </b-select> -->
          <input
            v-model.lazy="l_perPage"
            type="number"
            v-if="isPaginated"
            @change="onPerPageChange"
            class="input"
          />
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="folder-open" size="is-large"> </b-icon>
              </p>
              <p>{{ $t("table.empty") }}.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
import ModalTypeSelection from "@/components/ModalTypeSelection";
import { uaMixin } from "@/mixins/user-agent";
import config from "@/config";
export default {
  components: {
    TableColumnFilter,
    ModalTypeSelection,
  },
  mixins: [uaMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    isLoadingRows: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
    },
    perPage: {
      validator: function (value) {
        // The value must match one of these values
        return [10, 20, 50].indexOf(value) !== -1;
      },
    },
    totalRecords: {
      type: Number,
    },
    checkable: {
      type: Boolean,
      required: false,
      default: true,
    },
    detailed: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    backendSorting: {
      type: Boolean,
      default: false,
    },
    downloadTypes: {
      type: Array,
    },
    /**@deprecated */
    documentType: {
      validator: function (value) {
        // The value must match one of these values
        return (
          ["einvoice", "document", "history", "volume"].indexOf(value) !== -1
        );
      },
    },
    computeCellClass: {
      type: Function,
      default: () => "",
    },
    saveState: {
      type: Boolean,
      default: false,
    },
    stateKey: {
      type: String,
      default: "",
    },
    defaultOrderedColumnsNames: {
      type: Array,
      required: false,
      default: null,
    },
    showEmitStateCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableConfig: config.properties.tableConfig,
      l_data: [...this.data],
      // l_tableColumns: [],
      l_perPage: this.perPage,
      l_currentPage: 1,
      selectedDocList: [],
      /*
       * If the user selects also the documents not visible in the table
       */
      isSelectedTotal: false,
      displayDownloadDialog: false,
      rowsPerPageOptions: [10, 20, 50],
      displayColumnFilterModal: false,
      displayedColumnsFilterColumn: [],
      orderedColumnsNames: this.defaultOrderedColumnsNames,
      visibleColumnsNames: null,
      mStateKey: `t.${this.stateKey}`,
      emitColumnsStateClose: false,
      sortingPriority: [],
    };
  },
  watch: {
    // tableColumns: function (newValue) {
    //   const oldColumns = this.l_tableColumns;
    //   for (let newCol in newValue) {
    //     let equal = false;
    //     for (let oldCol in oldColumns) {
    //       if (oldCol.field == newCol.name) {
    //         equal = true;
    //         break;
    //       }
    //     }
    //     if (!equal) {
    //       this.l_tableColumns.push(newCol);
    //     }
    //   }
    // },
    data() {
      this.selectedDocList = [];
    },
    perPage: function (newValue) {
      this.l_perPage = newValue;
    },
  },
  computed: {
    backendSortingEnabled() {
      return this.backendSorting;
    },
    l_tableColumns() {
      return this.tableColumns.map((prop) => {
        let visibility = "visible" in prop ? prop.visible : true;
        return {
          visible: visibility,
          sortable: true,
          ...prop,
        };
      });
    },
    columns() {
      const orderedCols = [];
      // Add the columns in the saved order
      if (this.orderedColumnsNames) {
        for (let i = 0; i < this.orderedColumnsNames.length; i++) {
          let ocn = this.orderedColumnsNames[i];
          let foundCol = this.l_tableColumns.find((prop) => prop.field === ocn);
          if (foundCol) orderedCols.push({ ...foundCol });
        }
      }
      const allOrdered = [...orderedCols];
      // Add all the columns that are not stored in the saved order
      for (let i = 0; i < this.l_tableColumns.length; i++) {
        if (
          orderedCols.findIndex(
            (col) => col.field === this.l_tableColumns[i].field
          ) < 0
        ) {
          allOrdered.push({ ...this.l_tableColumns[i] });
        }
      }
      // Toggle visibility according to the saved list of visible columns
      if (this.visibleColumnsNames) {
        allOrdered.forEach((obj) => {
          const visible = this.visibleColumnsNames.indexOf(obj.field) !== -1;
          obj.visible = visible;
        });
      }
      return allOrdered;
    },
  },
  methods: {
    openDownloadDialog() {
      if (this.downloadTypes && this.downloadTypes.length)
        if (this.downloadTypes.length > 1) {
          this.displayDownloadDialog = true;
        } else {
          this.downloadSelected([this.downloadTypes[0].value]);
        }
    },
    closeDownloadDialog() {
      this.displayDownloadDialog = false;
    },
    downloadSelected(types) {
      this.closeDownloadDialog();
      var artifacts = 0;
      for (const type of types) {
        artifacts += type;
      }
      const payload = {
        artifacts,
        selected: [],
        metadata: [...this.columns.map((c) => c.field)],
      };
      // If the user wants to download all the documents, even those not visible in the table
      if (this.isSelectedTotal) {
        this.$emit("download-total", payload);
      } else {
        payload.selected = this.selectedDocList;
        this.$emit("download", payload);
      }
    },
    onCheckRows(checkedList) {
      if (checkedList.length < this.data.length) {
        this.isSelectedTotal = false;
      }
    },
    onClick(rowData) {
      if (this.clickable) {
        this.$emit("row-click", rowData);
      }
    },
    onCloseModalColFilter() {
      if (this.emitColumnsStateClose) {
        let state = {};
        state.order = this.orderedColumnsNames;
        state.visible = this.visibleColumnsNames;
        this.$emit("column-state-close", state);
      }
    },
    onContextMenu(rowData, event) {
      event.preventDefault();
      const text = event.target.textContent;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("text_copied_to_clipboard", { text: text }),
            position: "is-bottom-right",
            type: "is-primary",
          });
        })
        .catch((e) => console.error(e));
    },
    onPageChange(page) {
      this.l_currentPage = page;
      this.$emit("page", { page: page, rows: this.l_perPage });
    },
    onPerPageChange() {
      this.$emit("page", { page: this.l_currentPage, rows: this.l_perPage });
    },
    onSort(field, order) {
      console.log("onSort", { field, order });
      if (this.backendSortingEnabled) {
        let existingPriority = this.sortingPriority.find(
          (i) => i.field === field
        );
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          // request sorted data from backend
          this.sortingPriority.push({ field, order });
        }
        this.$emit("sort-data", this.sortingPriority);
      }
    },
    onSortingPriorityRemoved(field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field
      );
      this.$emit("sort-data", this.sortingPriority);
    },
    onTableColumnsOrderChange(newData) {
      this.orderedColumnsNames = newData.map((data) => data.field);
      this.storeState();
    },
    onTableColumnsToggleVisibility(visibleObjects) {
      this.visibleColumnsNames = visibleObjects.map((obj) => obj.field);
      this.storeState();
    },
    onTableColumnsToggleVisibilityAll(visibleObjects) {
      this.visibleColumnsNames = visibleObjects.map((obj) => obj.field);
      this.storeState();
    },
    sortColumn(a, b, isAsc) {
      console.log("a", a);
      console.log("b", b);
      console.log("isAsc", isAsc);
    },
    storeState() {
      let state = {};
      state.order = this.orderedColumnsNames;
      state.visible = this.visibleColumnsNames;
      if (this.saveState) {
        if (Object.keys(state).length) {
          localStorage.setItem(this.mStateKey, JSON.stringify(state));
        }
      }
      this.$emit("column-state", state);
    },
    selectTotal() {
      this.selectedDocList = Array.from(this.data);
      // this.selectedDocList.push(...this.data);
      this.isSelectedTotal = true;
    },
    unselectTotal() {
      this.selectedDocList = [];
      this.isSelectedTotal = false;
    },
  },
  beforeMount() {
    if (this.saveState) {
      const stateString = localStorage.getItem(this.mStateKey);
      if (stateString) {
        const restoredState = JSON.parse(stateString);
        this.orderedColumnsNames = restoredState.order;
        this.visibleColumnsNames = restoredState.visible;
      }
    }
  },
  created() {
    // this.l_tableColumns = this.tableColumns.map((prop) => {
    //   return {
    //     ...prop,
    //     visible: true,
    //     sortable: true,
    //   };
    // });

    this.displayedColumnsFilterColumn = [
      {
        field: "label",
        label: this.$t("table.col-options.column-label"),
      },
    ];
  },
};
</script>

<style></style>
